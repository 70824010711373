<script>
import HeroSection from '@/components/pages/ProductList/HeroSection.vue'
import ProductListSection from '@/components/pages/ProductList/ProductListSection.vue'
export default {
  components: {
    HeroSection,
    ProductListSection,
  },
  data() {
    return {}
  },

  mounted() {
    document.body.classList.add('alternate')
  },
  unmounted() {
    document.body.classList.remove('alternate')
  },
}
</script>

<template>
  <div class="productlist">
    <HeroSection />
    <ProductListSection id="products" />
  </div>
</template>

<style lang="scss" scoped>

</style>
