<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import PolygonIcon from '@/assets/svgs/polygonDown.vue'

export default {
  components: {
    PolygonIcon,
    BDropdown,
    BDropdownItem,
  },
  props: ['dropdownText', 'dropdownList'],
  methods: {
    filter(x) {
      this.$store.commit('filterProducts', x)
    },
  },
}
</script>

<template>
  <div>
    <BDropdown
      size="lg" variant="link" toggle-class="text-decoration-none" no-caret
      class="productlist__productsSection__FilterTab__dropdownbtn position-relative w-100"
      style="padding-right:50px;"
    >
      <template #button-content>
        <div class="d-flex align-items-center productlist__productsSection__FilterTab__allproductstab">
          <p class="productlist__productsSection__FilterTab__allproductstab__text">
            {{ dropdownText }}
          </p>
          <div>
            <PolygonIcon />
          </div>
        </div>
      </template>
      <BDropdownItem
        v-for="item in dropdownList" :key="item.id" style="background:#F8F8F8;"
        @click="filter(item)"
      >
        {{
          item.text
        }}
      </BDropdownItem>
    </BDropdown>
  </div>
</template>

<style lang="scss" scoped>

</style>
