<script>
import { BCarousel, BCarouselSlide, BModal } from 'bootstrap-vue'

export default {
  components: {
    BCarousel, BCarouselSlide, BModal,
  },
  data() {
    return {
      heading: 'Aïn Saïss',
      btnText: 'VOIR',
      currentText: 'Aïn Saïss Bulles est l\'alliée<br>idéale de vos repas, des<br>plus légers aux plus festifs. ',
      changeText: false,
      slides: [
        {
          id: '12scdw4',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_SLIDE-1.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-1.webp'),
        },
        {
          id: '12scdwx4',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_SLIDE-2.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-2.webp'),
        },
        {
          id: '12scdw24a',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_SLIDE-3.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-3.webp'),
        },
        {
          id: '12scdw24s',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_SLIDE-4.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-4.webp'),
        },
        {
          id: '12scdw24d',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_SLIDE-5.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-5.webp'),
        },
      ],
      mobile_slides: [
        {
          id: '12scdw4',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_MOBILE_SLIDE-1.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-1.webp'),
        },
        {
          id: '12scdwx4',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_MOBILE_SLIDE-2.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-2.webp'),
        },
        {
          id: '12scdw24a',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_MOBILE_SLIDE-3.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-3.webp'),
        },
        {
          id: '12scdw24s',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_MOBILE_SLIDE-4.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-4.webp'),
        },
        {
          id: '12scdw24d',
          text: 'ain saiss, pour le plaisir d avancer dand la vie!',
          imageUrl: require('../../../assets/images/slides/AS_MOBILE_SLIDE-5.webp'),
          modalImage: require('../../../assets/images/slides/modals/slide-5.webp'),
        },
      ],
      swiperOption: {
        direction: 'vertical',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      currentModalImage: '',
    }
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  methods: {
    showModal(slide) {
      this.currentModalImage = slide.modalImage
      // this.$refs.modalRef.show()
      this.$refs['slide-modal'].show()
    },
    changeSlide() {
      if (activeIndex === 0) {
        this.currentText
                    = 'Aïn Saïss Bulles est l\'alliée<br>idéale de vos repas, des<br>plus légers aux plus festifs. '
      }
      if (activeIndex === 1) {
        this.currentText
                    = 'Naturellement pure et<br>légère, Aïn Saïss est votre<br> partenaire au quotidien.'
      }
      if (activeIndex === 2) {
        this.currentText
                    = 'Laissez-vous emporter par<br>un plaisir fruité unique et<br> un goût rafraichissant avec<br>Aïn Saïss Déliss.'
      }
    },
    hideModal() {
      this.$refs['slide-modal'].hide()
    },
  },
}
</script>

<template>
  <div class="productlist__herosection buefy">
    <BCarousel
      id="carousel-no-animation" style="text-shadow: 0px 0px 2px #000" class="desktop_content"
      no-animation indicators img-width="1024" img-height="480" :fade="true"
    >
      <div v-for="(slide, index) in slides" :key="index" style="cursor: pointer;" @click="showModal(slide)">
        <BCarouselSlide :img-src="slide.imageUrl" />
      </div>
    </BCarousel>
    <BCarousel
      id="carousel-no-animation" class="mobile_content" style="text-shadow: 0px 0px 2px #000;"
      no-animation indicators img-width="1024" img-height="480" :fade="true"
    >
      <div v-for="(slide, index) in mobile_slides" :key="index" style="cursor: pointer;" @click="showModal(slide)">
        <BCarouselSlide :img-src="slide.imageUrl" />
      </div>
    </BCarousel>
    <BModal ref="slide-modal" size="lg" centered hide-footer hide-header body-class="p-0">
      <button type="button" class="close" @click="hideModal">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal_body">
        <img
          :src="currentModalImage"
          alt="image"
          class="d-none d-sm-block "
          style="width: 100%; height: auto;"
        >
        <img
          :src="currentModalImage"
          alt="image"
          class="d-block d-sm-none"
          style="width: 100%; height: auto;"
        >
      </div>
    </BModal>
  </div>
</template>

<style lang="scss">
.mobile_content {
    @media (min-width: 750px) {
        display: none;
    }
}

.desktop_content {
    @media (max-width: 750px) {
        display: none;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
    {
    opacity: 0;
}

.background-div {
    position: absolute;
    bottom: 0;
    left: 0;
    background-position: top;
    height: 22vw;
    width: 100%;
    z-index: 0;
    background-size: 100%;
}
.modal_body {
    background: #addaf6;
    @media (min-width: 576px) {
        background:#cce6f8 ;
     }
}

.close{
    position: absolute;
    top: -15px;
    right: 0;
    z-index: 1;
    padding: 1rem;
    color: #ec168c;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    transition: opacity .25s ease-in-out;
}
</style>
