<script>
import { BModal } from 'bootstrap-vue'
import ProductList from '@/components/pages/ProductList.vue'
import CartIcon from '@/assets/svgs/ButtonCartIcon.vue'

export default {
  components: {
    ProductList,
    BModal,
    CartIcon,
  },
  data() {
    return {
      mouseon: false,
    }
  },
  mounted() {
    // const popup = localStorage.getItem('popup-coming-soon')
    // if (!popup) {
    //   this.showModal()
    //   localStorage.setItem('popup-coming-soon', true)
    // }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    handleClick() {
      this.hideModal()
      this.$router.push(
        { name: 'ProductDetail', params: { id: 29 } },
      )
    },
  },
}
</script>

<template>
  <div>
    <ProductList />
    <div>
      <BModal ref="my-modal" size="lg" centered hide-footer hide-header body-class="p-0">
        <button type="button" class="close" @click="hideModal">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal_body">
          <img
            src="/pop-up-coming-soon.jpeg"
            alt="image"
            class="d-none d-sm-block "
            style="width: 100%; height: auto;"
          >
          <img
            src="/pop-up-coming-soon.jpeg"
            alt="image"
            class="d-block d-sm-none"
            style="width: 100%; height: auto;"
          >
          <div class="d-flex justify-content-center">
            <!-- <button
              class="btn productlist__productsSection__product__btn d-flex my-4"
              @click="handleClick" @mouseenter="mouseon = true"
              @mouseleave="mouseon = false"
            >
              <div class="productlist__productsSection__product__btn__iconContainer" :class="{ move: mouseon }">
                <CartIcon :color="mouseon === true ? '#ffff' : '#ec168c'" />
              </div>
              <div class="productlist__productsSection__product__btn__division" />
              <p class="productlist__productsSection__product__btn__text text-uppercase">
                COMMANDER
              </p>
            </button> -->
          </div>
        </div>
      </BModal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.productlist__productsSection__product__btn {
    width: auto !important;
}

.modal_body {
    background: #addaf6;
    @media (min-width: 576px) {
        background:#FFFFFF ;
        padding: 2px;
     }
}

.close{
    position: absolute;
    top: -20px;
    right: 0px;
    z-index: 1;
    padding: 1rem;
    // color: #ec168c;
    color: #FFFFFF;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    transition: opacity .25s ease-in-out;
}
</style>
