<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
    <g id="Polygon_3" data-name="Polygon 3" transform="translate(20 17) rotate(180)" fill="none">
      <path d="M10,0,20,17H0Z" stroke="none" />
      <path
        d="M 10 3.944619178771973 L 3.496833801269531 15 L 16.50316619873047 15 L 10 3.944619178771973 M 10 0 L 20 17 L 0 17 L 10 0 Z"
        stroke="none" fill="#ec168c"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>

</style>
