<script>
import FilterTabs from '@/components/pages/ProductList/FilterTabs.vue'
import Product from '@/components/pages/ProductList/Product.vue'
// import LeftIcon from '@/assets/svgs/pages/LeftPolygon.vue'
// import RightIcon from '@/assets/svgs/pages/RightPolygon.vue'
export default {
  components: {
    FilterTabs,
    Product,
    // LeftIcon,
    // RightIcon,
  },
  data() {
    return {
      pageNumber: 1,
      heading: 'Tous les produits',
      products: [],
      url: 'products?page=0&size=50&orderBy=&order=DESC&enabled=&query=',
      totalPages: 0,
      currentPage: 0,
      categories: [],
    }
  },
  computed: {
    prods() {
      if (this.$store.state.filtered_product_ids) {
        const new_prods = this.products.filter(({ id }) =>
          this.$store.state.filtered_product_ids.includes(id),
        )
        return new_prods
      }
      else {
        return this.products
      }
    },
  },

  async mounted() {
    // Only keep AS products
    const productsData = await this.$store.dispatch('getProducts', this.url)
    this.products = productsData.content.filter((prod) => {
      if (prod.brand === 'AIN_SAISS' && prod.status)
        return true
      return false
    })

    const sku_order = [
      'S531',
      'S564',
      'S521',
      'S520',
      'S567',
      'S541',
      'S533',
      'S510',
      'S527',
      'AS23455',
      'ASMANG05',
      'ASDELISSMANGUE',
      'S538',
      'S537',
      'S557',
      'S558',
    ]

    // Change order of products display

    const compare = (a, b) => {
      let pos_a = sku_order.findIndex(item => item === a.sku)
      let pos_b = sku_order.findIndex(item => item === b.sku)
      pos_a = pos_a === -1 ? 1e5 : pos_a
      pos_b = pos_b === -1 ? 1e5 : pos_b
      return (pos_a > pos_b) ? 1 : (pos_b > pos_a) ? -1 : 0
    }
    this.products.sort(compare)

    this.$store.state.products = this.products.map(({ id, categories }) => ({
      product_id: id,
      categories: categories.map(({ id }) => id),
    }))

    this.currentPage = productsData.pageable.pageNumber + 1
    this.totalPages = productsData.totalPages
    // get categories from products
    this.categories = this.products
      .map(({ categories }) =>
        categories.map(({ id, nameFrench }) => ({ id, text: nameFrench })),
      )
      .flat()
    // unique
    this.categories = this.categories.filter(
      (value, index, self) =>
        index
        === self.findIndex(t => t.id === value.id && t.text === value.text),
    )
    this.categories.unshift({ id: -1, text: 'Tous les produits' })
  },
}
</script>

<template>
  <div class="productlist__productsSection d-flex justify-content-center">
    <div class="productlist__productsSection__container text-center py-5">
      <h1 class="text-center productlist__productsSection__heading">
        {{ heading }}
      </h1>
      <FilterTabs :categories="categories" style="z-index: 999999" />

      <div class="row">
        <div
          v-for="data in prods"
          :key="data.id"
          class="col-6 col-xl-4 productlist__productsSection__productColumn"
        >
          <Product :product="data" />
        </div>
      </div>

      <!-- <div
        class="productlist__productsSection__pages d-flex align-items-center justify-content-between mt-5 p-0"
      >
        <button class="btn p-0 m-0"><LeftIcon /></button>
        <p class="productlist__productsSection__pages__text text-center m-0 p-0">
          PAGE {{ currentPage }}
        </p>

        <button class="btn p-0 m-0"><RightIcon /></button>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
