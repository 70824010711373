<script>
import ProductDropDownTab from '@/components/pages/ProductList/ProductDropdown.vue'
export default {
  components: {
    ProductDropDownTab,
  },
  props: ['categories'],
  data() {
    return {
      allproducsText: 'Tous les produits',
      morefilters: 'Nos Gammes.',
      dropdownList: [
        {
          id: '87dhbe',
          text: 'Ain Saiss',
        },
        {
          id: '87dhbd',
          text: 'Ain Saiss Kids',
        },
        {
          id: '87dhbh',
          text: 'Ain Saiss Aromatisé',
        },
        {
          id: '87dhbi',
          text: 'Ain Saiss Verre',
        },
        {
          id: '87dhbj',
          text: 'Ain Saiss Bulles',
        },
      ],
      inputData: {
        id: '232',
        type: 'text',
        placeholder: 'Rechercher un produit',
        inputClass: 'productlist__productsSection__FilterTab__searchbox__input',
        class2: 'productlist__productsSection__FilterTab__searchbox__label',
        class:
                    'productlist__productsSection__FilterTab__iconInputContainer text-center',
      },
    }
  },
}
</script>

<template>
  <div style="z-index: 2">
    <!-- filter tabs for screen greater than 1199px -->
    <div
      class="
        productlist__productsSection__FilterTab
        align-items-baseline
        lg
        d-flex
      " style="z-index: 2"
    >
      <!-- <ProductDropDownTab
        :dropdownText="allproducsText"
        :dropdownList="dropdownList"
      />

      <IconInput :inputData="inputData">
        <template v-slot:inputiconright>
          <div
            class="
              productlist__productsSection__FilterTab__searchbox__input__icon
            "
          >
            <SearchIcon />
          </div>
        </template>
      </IconInput> -->

      <ProductDropDownTab style="z-index: 2;" :dropdown-text="morefilters" :dropdown-list="categories" />
    </div>

    <!-- filter tabs for smaller screens -->
    <div
      class="
        productlist__productsSection__FilterTab
        sm
        d-flex
        justify-content-center
      "
    >
      <div class="w-100">
        <!-- <IconInput :inputData="inputData">
          <template v-slot:inputiconright>
            <div
              class="productlist__productsSection__FilterTab__searchbox__input__icon "
            >
              <SearchIcon />
            </div>
          </template>
        </IconInput> -->

        <div
          class="
            d-flex
            align-items-center
            justify-content-around
            productlist__productsSection__FilterTab__filtersContainer
          "
        >
          <!-- <ProductDropDownTab
            :dropdownText="allproducsText"
            :dropdownList="dropdownList"
          /> -->

          <ProductDropDownTab
            style="z-index: 2;" :dropdown-text="morefilters"
            :dropdown-list="categories"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
