<script>
import CartIcon from '@/assets/svgs/ButtonCartIcon.vue'
export default {
  name: 'Product',
  components: { CartIcon },
  props: ['product'],
  data() {
    return {
      mouseon: false,
    }
  },

  methods: {
    addProductToCart() {
      const prod = { ...this.product }
      const payload = {
        id: prod.id,
        title: prod.nameFrench,
        price: prod.price,
        image: prod.images[0].content,
        quantity: 1,
        sum: prod.price,
      }
      this.$store.commit('addProductToCart', payload)
    },

    goToDetailPage() {
      // this.$store.
      this.$router.push({
        name: 'ProductDetail',
        params: { id: this.product.id },
      })
    },
  },
}
</script>

<template>
  <div class="productlist__productsSection__product position-relative">
    <div class="productlist__productsSection__product__subcontainer" @click="goToDetailPage">
      <div class="productlist__productsSection__product__imgContainer">
        <img
          :src="`data:image/jpg;base64,${product.images[0].content}`"
          class="img-fluid productlist__productsSection__product__img"
        >
      </div>

      <div
        class="
          productlist__productsSection__product__priceBox
          position-absolute
        "
      >
        <p class="productlist__productsSection__product__priceBox__price">
          <span>{{ product.price.toFixed(2) }} </span>
          <span
            class="
              productlist__productsSection__product__priceBox__price__currency
            "
          >
            Dh
          </span>
          <!-- <span>
            <p
              style="font-size: 15px; color: #ec1e8c; line-height: 17px"
              class="mx-2"
            >
              {{ product.lightDescriptionFrench }}
            </p>
          </span> -->
        </p>
      </div>

      <h1 class="text-center productlist__productsSection__product__title">
        {{ product.nameFrench }}
      </h1>
      <p class="text-center productlist__productsSection__product__size">
        {{ product.lightDescriptionFrench }}
      </p>
    </div>
    <button
      class="btn productlist__productsSection__product__btn d-flex" @mouseenter="mouseon = true"
      @mouseleave="mouseon = false" @click="addProductToCart"
    >
      <div class="productlist__productsSection__product__btn__iconContainer" :class="{ move: mouseon }">
        <CartIcon :color="mouseon == true ? '#ffff' : '#ec168c'" />
      </div>
      <div class="productlist__productsSection__product__btn__division" />
      <p class="productlist__productsSection__product__btn__text text-uppercase">
        ajouter
      </p>
    </button>
  </div>
</template>

<style lang="scss" scoped>

</style>
