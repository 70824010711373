<script>
export default { props: ['color'] }
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31.669" height="29.026" viewBox="0 0 31.669 29.026">
    <path
      id="Path_3358" data-name="Path 3358"
      d="M564.225,817.46H539.464l-.368-4.1a1.3,1.3,0,0,0-1.3-1.184h-2.642a1.3,1.3,0,1,0,0,2.6h1.453c.794,8.849-1.259-14.022,1.5,16.665a5,5,0,0,0,1.873,3.39,3.942,3.942,0,1,0,6.813,1.084h7.118a3.943,3.943,0,1,0,3.708-2.6H543.094a2.415,2.415,0,0,1-2.22-1.464l20.785-1.221a1.3,1.3,0,0,0,1.185-.983l2.643-10.57a1.3,1.3,0,0,0-1.262-1.616ZM543.086,838.6a1.342,1.342,0,1,1,1.342-1.342A1.343,1.343,0,0,1,543.086,838.6Zm14.533,0a1.342,1.342,0,1,1,1.342-1.342A1.343,1.343,0,0,1,557.619,838.6Zm2.933-10.511-20.028,1.177-.826-9.2h22.862Z"
      transform="translate(-533.858 -812.175)" :fill="color || '#ec168c'"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
